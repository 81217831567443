<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="createSerie">
      <b-form
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2" >{{ $t('DetallesSerie') }}</h4>
          </b-col>
          <b-col>
            <validation-provider
                #default="{ errors }"
                :name="$t('Nombre')"
                rules="required"
            >
              <b-form-group
                  :label="$t('Nombre')"
                  label-for="serie-name"
              >
                <b-form-input
                    v-model="name"
                    name="name"
                    :placeholder="$t('Nombre')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12">
            <validation-provider
                #default="{ errors }"
                :name="$t('Descripcion')"
                rules=""
            >
              <b-form-group
                  :label="$t('Descripcion')"
                  label-for="asset-description"
              >
                <quill-editor
                    v-model="description"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12" v-if="currentRole === 'admin_empresa' || currentRole === 'super_admin'">
            <validation-provider
                #default="{ errors }"
                :name="$t('Cliente')"
                :rules="'required'"
            >
              <b-form-group :label="$t('Cliente')" label-for="account-clients">
                <v-select
                  label="name"
                  :filterable="true"
                  :searchable="true"
                  v-model="client"
                  :options="selectClients"
                  :placeholder="$t('Cliente')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12 text-right">
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t('Enviar') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      name: '',
      client: '',
      description: '',
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
      currentRole: 'auth/getRole',
      currentCompany: 'companies/getCurrentCompany',
      selectClients: 'clients/getSelectClients',
    }),
  },
  methods: {
    ...mapActions({
      create: 'series/create',
      searchClients: 'clients/selectClients',

    }),
    handleSubmit() {
      this.$refs.createSerie.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          if (formData) {
            this.create({ serie: formData })
          }
        }
      })
    },
    createFormData() {
      const data = new FormData()
      data.append('name', this.name)
      data.append('description', this.description)
      if (this.currentRole === 'admin_empresa' || this.currentRole === 'super_admin') {
        data.append('client_id', this.client.id)
      } else {
        data.append('client_id', this.currentClient.id)
      }

      return data
    },
  },
  async created() {
    if (this.currentClient) {
      this.client = this.currentClient
    }
    await this.searchClients({ company: this.currentCompany.id })
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
